<template>
  <div id="login" class="popUp">
    <div class="content">
      <h1 class="title noMargin">PS Time Tracking</h1>
      <form @submit.prevent>
        <div class="input">
          <label for="userName">Username</label>
          <input type="text" id="userName" v-model="userName" />
        </div>
        <div class="input">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" />
        </div>
        <button class="btn primary" @click="login()">Login</button>
      </form>

      <template v-if="loginError.length">
        <hr />
        <div class="dialog alert">
          <p class="smallText">{{ loginError }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import api from "@/lib/api";
import router from "@/router";
import store from "@/store";
import sha256 from "crypto-js/sha256";

export default {
  data() {
    return {
      userName: "",
      password: "",
      loginError: "",
    };
  },

  methods: {
    login() {
      let user = {
        username: this.userName,
        password: sha256(this.password).toString(),
      };
      api
        .post("login", user)
        .then((token) => {
          if (typeof token.data !== "undefined") {
            store.commit("setToken", token.data);
          } else {
            this.loginError =
              "Sorry! The information related to this username could not be found. Please try again!";
            return;
          }
          api
            .get("engineers")
            .then((response) => {
              user = response.data.find((u) => u.username == user.username);
              user["lastLogin"] = new Date();

              if (typeof user !== "undefined") {
                store.commit("setUser", user);

                router.push("/resources/hourentries");
              } else {
                this.loginError =
                  "Sorry! The information related to this username could not be found. Please try again!";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
          this.loginError =
            "Sorry! The information you entered did not match our records. Please try again!";
        });
    },
  },
};
</script>
