<template>
  <div id="sidebar" class="float-left">
    <div class="userName">
      <strong @click="userSettings = !userSettings">
        {{ user.name }}
      </strong>

      <ul v-if="userSettings" class="userSettings">
        <li>
          <button class="plain" @click="logout()">Logout</button>
        </li>
        <li>
          <button class="plain" @click="changePassword = true">
            Change Password
          </button>
        </li>
      </ul>
    </div>
    <nav id="sidebarMenu">
      <ul>
        <template v-if="user.role == 'admin'">
          <li>
            <strong class="label uppercase">Manage</strong>

            <ul v-for="resourceType in resources" :key="resourceType">
              <li>
                <router-link
                  class="capitalize"
                  :to="'/resources/' + resourceType"
                >
                  <span class="hasIcon" :class="resourceType"></span>
                  {{ " " + resourceType }}
                </router-link>
              </li>
            </ul>
          </li>
        </template>

        <li>
          <strong class="label uppercase">Track</strong>
          <ul>
            <li>
              <router-link to="/resources/hourentries">
                <span class="hasIcon hourentries"></span>
                Hour Entries
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <ChangePassword
      v-if="changePassword"
      :engineerId="user.id"
      @closePopUp="changePassword = false"
    ></ChangePassword>
  </div>
</template>

<script>
import api from "@/lib/api";
import store from "@/store";
import router from "@/router";

import ChangePassword from "../ChangePassword.vue";

export default {
  data() {
    return {
      resources: ["engineers", "customers", "projects"],
      polling: null,
      expand: {
        customers: false,
        engineers: false,
        projects: false,
      },
      changePassword: false,
      userSettings: false,
    };
  },

  components: {
    ChangePassword,
  },

  computed: {
    customers() {
      return store.state.customers;
    },

    engineers() {
      return store.state.engineers;
    },

    projects() {
      return store.state.projects;
    },

    user() {
      return store.state.user;
    },
  },

  mounted() {
    this.fetchResources();
  },

  methods: {
    fetchResources() {
      this.resources.forEach((type) => {
        this.getResources(type);
      });
    },

    getResources(type) {
      api
        .get(type)
        .then((response) => {
          store.commit("addResources", {
            type: type,
            resources: response.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    logout() {
      store.commit("setToken", null);
      store.commit("setUser", null);
      router.push("/");
      clearInterval(this.polling);
    },
  },
};
</script>

<style lang="scss">
#sidebar {
  width: 250px;
  background: #fff;
  display: block;
  height: 100vh;
  padding: 40px;

  .userName {
    display: inline-block;
    width: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 4px;
    color: var(--textColor);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+CjxyZWN0IGZpbGw9Im5vbmUiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIvPgo8cG9seWxpbmUgZmlsbD0iIzg1ODU4RiIgcG9pbnRzPSIxMCwxNC4yIDE3LDcuMiAxNS42LDUuOCAxMCwxMS40IDQuNCw1LjggMyw3LjIgMTAsMTQuMiAiLz4KPC9zdmc+Cg==);
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 96% 12px;
    transform: translateX(-6px);

    strong {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYyMEgweiIvPjxwYXRoIGZpbGw9IiM4NTg1OTAiIGQ9Ik04LjIgOS4zQzYuNCA5LjQgNC45IDggNC44IDYuMnYtLjNjLS4xLTEuOCAxLjMtMy4zIDMuMS0zLjRoLjNjMS44LS4xIDMuMyAxLjMgMy40IDMuMXYuM2MuMSAxLjgtMS4zIDMuMy0zLjEgMy40aC0uM3pNMSAxNi41di0yLjFjMC0uNS4xLTEgLjQtMS40LjMtLjQuNy0uOCAxLjEtMSAxLS40IDItLjggMy0xIC45LS4yIDEuOC0uMyAyLjctLjNoLjVjLS4xLjItLjIuNC0uMi42LS4xLjItLjEuNS0uMS43aC0uMmMtLjkgMC0xLjcuMS0yLjYuMy0uOS4yLTEuOC41LTIuNi45LS4yLjEtLjQuMy0uNS41LS4xLjItLjIuNC0uMi43di44aDYuMWMuMS4zLjIuNS4zLjcuMS4yLjIuNC40LjZIMXptMTMuMyAxLS4yLTEuNWMtLjMtLjEtLjUtLjItLjgtLjMtLjItLjEtLjUtLjMtLjctLjVsLTEuMi4zLS42LS45IDEuMS0xYy0uMS0uNC0uMS0uOCAwLTEuMWwtMS4xLTEgLjYtLjkgMS4yLjNjLjItLjIuNC0uNC43LS41LjItLjEuNS0uMi44LS4zbC4yLTEuNWgxLjJsLjIgMS41Yy4zLjEuNS4yLjguMy4yLjEuNS4zLjcuNWwxLjItLjMuNi45LTEuMSAxYy4xLjQuMS44IDAgMS4xbDEuMSAxLS42LjktMS4yLS4zYy0uMi4yLS40LjQtLjcuNS0uMi4xLS41LjItLjguM2wtLjIgMS41aC0xLjJ6bS42LTIuN2MuOS0uMSAxLjctLjkgMS42LTEuOC0uMS0uOS0uOS0xLjctMS44LTEuNi0uOS4xLTEuNy45LTEuNiAxLjggMCAuNC4yLjguNSAxLjEuMy4zLjguNSAxLjMuNXpNOC4yIDcuOWMxLjEgMCAyLjEtLjggMi4xLTIgMC0xLjEtLjgtMi4xLTItMi4xaC0uMWMtMS4xIDAtMi4xLjgtMi4xIDIgMCAxLjEuOCAyLjEgMiAyLjFoLjF6Ii8+PC9zdmc+)
        5px 9px no-repeat;
      background-size: 16px;
      cursor: pointer;
      display: block;
      padding: 5px;
      padding-left: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      color: var(--labelColor);

      &:hover {
        color: var(--primary);
      }
    }
  }

  .userSettings {
    border-top: 1px solid var(--borderColor);
    padding: 5px 5px 10px 25px;
  }

  li {
    font-size: 1rem;

    .hasIcon {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYyMEgweiIvPjxnIGZpbGw9IiM4NTg1OTAiPjxwYXRoIGQ9Im0xMy44IDEzLjgtNC43LTIuOVY1LjVoMS4zdjQuN2w0LjEgMi40LS43IDEuMnoiLz48cGF0aCBkPSJNMTAgMi40Yy00LjIgMC03LjYgMy40LTcuNiA3LjYgMCAyIC44IDQgMi4yIDUuNCAzIDMgNy44IDIuOSAxMC44LS4xIDIuOS0zIDIuOS03LjcgMC0xMC43QzE0IDMuMiAxMiAyLjQgMTAgMi40TTEwIDFjNSAwIDkgNCA5IDlzLTQgOS05IDktOS00LTktOSA0LTkgOS05eiIvPjwvZz48L3N2Zz4=);
      width: 16px;
      height: 16px;
      top: 2px;

      &.customers {
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYyMEgweiIvPjxwYXRoIGZpbGw9IiM4NTg1OTAiIGQ9Ik0yLjMgMTguNWMtLjQgMC0uNy0uMS0uOS0uNC0uMy0uMi0uNC0uNi0uNC0uOVY2LjVjMC0uNC4xLS43LjQtLjkuMi0uMy42LS40LjktLjRoNC4xVjNjMC0uNC4xLS43LjQtLjkuMi0uMy42LS40LjktLjRoNC41Yy40IDAgLjcuMS45LjQuMy4yLjQuNi40Ljl2Mi4yaDQuMWMuNCAwIC43LjEuOS40LjMuMi40LjYuNC45djEwLjdjMCAuNC0uMS43LS40LjktLjIuMy0uNi40LS45LjRIMi4zem0wLTEuM2gxNS4zVjYuNUgyLjN2MTAuN3pNNy44IDUuMWg0LjVWMi45SDcuOHYyLjJ6TTIuMyAxNy4yeiIvPjwvc3ZnPg==);
      }

      &.engineers {
        /* background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYyMEgweiIvPjxwYXRoIGZpbGw9IiM4NTg1OTAiIGQ9Ik04LjIgOS4zQzYuNCA5LjQgNC45IDggNC44IDYuMnYtLjNjLS4xLTEuOCAxLjMtMy4zIDMuMS0zLjRoLjNjMS44LS4xIDMuMyAxLjMgMy40IDMuMXYuM2MuMSAxLjgtMS4zIDMuMy0zLjEgMy40aC0uM3pNMSAxNi41di0yLjFjMC0uNS4xLTEgLjQtMS40LjMtLjQuNy0uOCAxLjEtMSAxLS40IDItLjggMy0xIC45LS4yIDEuOC0uMyAyLjctLjNoLjVjLS4xLjItLjIuNC0uMi42LS4xLjItLjEuNS0uMS43aC0uMmMtLjkgMC0xLjcuMS0yLjYuMy0uOS4yLTEuOC41LTIuNi45LS4yLjEtLjQuMy0uNS41LS4xLjItLjIuNC0uMi43di44aDYuMWMuMS4zLjIuNS4zLjcuMS4yLjIuNC40LjZIMXptMTMuMyAxLS4yLTEuNWMtLjMtLjEtLjUtLjItLjgtLjMtLjItLjEtLjUtLjMtLjctLjVsLTEuMi4zLS42LS45IDEuMS0xYy0uMS0uNC0uMS0uOCAwLTEuMWwtMS4xLTEgLjYtLjkgMS4yLjNjLjItLjIuNC0uNC43LS41LjItLjEuNS0uMi44LS4zbC4yLTEuNWgxLjJsLjIgMS41Yy4zLjEuNS4yLjguMy4yLjEuNS4zLjcuNWwxLjItLjMuNi45LTEuMSAxYy4xLjQuMS44IDAgMS4xbDEuMSAxLS42LjktMS4yLS4zYy0uMi4yLS40LjQtLjcuNS0uMi4xLS41LjItLjguM2wtLjIgMS41aC0xLjJ6bS42LTIuN2MuOS0uMSAxLjctLjkgMS42LTEuOC0uMS0uOS0uOS0xLjctMS44LTEuNi0uOS4xLTEuNy45LTEuNiAxLjggMCAuNC4yLjguNSAxLjEuMy4zLjguNSAxLjMuNXpNOC4yIDcuOWMxLjEgMCAyLjEtLjggMi4xLTIgMC0xLjEtLjgtMi4xLTItMi4xaC0uMWMtMS4xIDAtMi4xLjgtMi4xIDIgMCAxLjEuOCAyLjEgMiAyLjFoLjF6Ii8+PC9zdmc+); */
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIGlkPSJMYXllcl8xIiB4PSIwIiB5PSIwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMCAyMCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMjAgMjAiPjxzdHlsZT4uc3Qxe2ZpbGw6Izg1ODU5MH08L3N0eWxlPjxwYXRoIGQ9Ik0wIDBoMjB2MjBIMFYweiIgc3R5bGU9ImZpbGw6bm9uZSIvPjxwYXRoIGQ9Ik01LjggMTEuNWMtMS40LjEtMi43LTEtMi43LTIuNXYtLjJjLS4xLTEuNCAxLTIuNyAyLjUtMi43aC4yYzEuNC0uMSAyLjcgMSAyLjcgMi41di4yYy4xIDEuNC0xIDIuNy0yLjUgMi43aC0uMnptMC0xLjFjLjkgMCAxLjctLjYgMS43LTEuNiAwLS45LS42LTEuNy0xLjYtMS43aC0uMWMtLjkgMC0xLjcuNi0xLjcgMS42IDAgLjkuNyAxLjcgMS43IDEuNy0uMSAwIDAgMCAwIDB6TTExLjYgMTQuNWMtLjItLjMtLjYtLjYtLjktLjgtLjgtLjMtMS42LS42LTIuNC0uOC0uNy0uMi0xLjQtLjItMi4xLS4yaC0uNWMtLjcgMC0xLjQuMS0yLjEuMi0uOC4yLTEuNi41LTIuNC44LS4zLjItLjYuNS0uOS44cy0uMy43LS4zIDEuMnYyLjdoMTEuOXYtMi43YzAtLjUtLjEtLjktLjMtMS4yem0tLjcgMi44SDF2LTEuNmMwLS4yLjEtLjQuMi0uNi4xLS4yLjItLjMuNC0uNC43LS4zIDEuNC0uNiAyLjEtLjcuNy0uMiAxLjQtLjIgMi4xLS4ySDZjLjcgMCAxLjQuMSAyLjEuMi43LjIgMS40LjQgMi4xLjcuMi4xLjMuMi40LjQuMS4yLjIuMy4yLjZ2MS42ek0xMy45IDguMWguMmMxLjQtLjEgMi42LTEuMyAyLjUtMi43di0uM2MtLjEtMS40LTEuMy0yLjYtMi43LTIuNWgtLjJjLTEuNC4xLTIuNiAxLjMtMi41IDIuN3YuMmMwIDEuNSAxLjIgMi43IDIuNyAyLjZ6bTAtNC40YzEgMCAxLjcuOCAxLjcgMS43IDAgMS0uOCAxLjYtMS43IDEuNmgtLjFjLTEgMC0xLjYtLjgtMS42LTEuNyAwLTEgLjgtMS42IDEuNy0xLjZ6TTE5LjYgMTFjLS4yLS4zLS41LS42LS44LS43LS44LS4zLTEuNi0uNi0yLjQtLjgtLjctLjItMS40LS4yLTIuMS0uMmgtLjVjLS43IDAtMS40LjEtMi4xLjItLjguMi0xLjcuMy0yLjQuOC0uNS40LS44LjktMS4xIDEuNy4yIDAgLjEuMS4zLjEuMiAwIC40LjIuNi4yIDAgMCAuMS0uMi4zLS41LjItLjQuNS0uNyAxLS45LjUtLjIgMS0uMyAxLjUtLjUuNy0uMiAxLjQtLjIgMi4xLS4yaC4xYy43IDAgMS40LjEgMi4xLjIuNy4yIDEuNC40IDIuMS43LjIuMS4zLjIuNC40LjEuMi4yLjMuMi42djEuNmgtNi41Yy4yLjMuMi43LjIgMUgyMFYxMmMwLS4zLS4xLS43LS40LTF6IiBjbGFzcz0ic3QxIi8+PC9zdmc+");
      }

      &.projects {
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYyMEgweiIvPjxwYXRoIGZpbGw9IiM4NTg1OTAiIGQ9Ik00LjIgMTljLTEuMyAwLTIuNC0xLTIuNC0yLjN2LTIuOGgyLjlWMWwxLjQgMS4zTDcuNSAxbDEuMyAxLjNMMTAuMiAxbDEuNCAxLjNMMTMgMWwxLjQgMS4zTDE1LjggMWwxLjQgMS4zTDE4LjYgMXYxNS42YzAgMS4zLTEgMi40LTIuMyAyLjRINC4yem0xMS42LTEuM2MuNSAwIDEtLjQgMS0uOVYzLjNINnYxMC42aDguOHYyLjhjMCAuNS40LjkgMSAxek03LjIgNi44VjUuNWg1LjR2MS4zSDcuMnptMCAzVjguNWg1LjR2MS4zSDcuMnptNy41LTNjLS40IDAtLjctLjMtLjctLjcgMC0uNC4zLS43LjctLjcuNCAwIC43LjMuNy43IDAgLjItLjEuNC0uMi41LS4xLjEtLjMuMi0uNS4yem0wIDIuOWMtLjQgMC0uNy0uMy0uNy0uNyAwLS40LjMtLjcuNy0uNy40IDAgLjcuMy43LjcgMCAuMi0uMS40LS4yLjUtLjEuMS0uMy4yLS41LjJ6bS0xMC42IDhoOS4zdi0yLjVIMy4xdjEuNWMwIC4zLjEuNS4zLjcuMi4yLjUuMy43LjN6bS0uOSAweiIvPjwvc3ZnPg==);
      }

      &.hourentries {
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYyMEgweiIvPjxnIGZpbGw9IiM4NTg1OTAiPjxwYXRoIGQ9Im0xMy44IDEzLjgtNC43LTIuOVY1LjVoMS4zdjQuN2w0LjEgMi40LS43IDEuMnoiLz48cGF0aCBkPSJNMTAgMi40Yy00LjIgMC03LjYgMy40LTcuNiA3LjYgMCAyIC44IDQgMi4yIDUuNCAzIDMgNy44IDIuOSAxMC44LS4xIDIuOS0zIDIuOS03LjcgMC0xMC43QzE0IDMuMiAxMiAyLjQgMTAgMi40TTEwIDFjNSAwIDkgNCA5IDlzLTQgOS05IDktOS00LTktOSA0LTkgOS05eiIvPjwvZz48L3N2Zz4=);
      }
    }

    a {
      color: #000e1a;
      display: inline-block;
      padding-top: 4px;
    }
  }

  nav > ul > li {
    margin-top: 20px;

    .router-link-active {
      font-weight: bold;
    }
  }
}
</style>
