<script setup>
import { RouterView } from "vue-router";
import SideBar from "@/components/nav/SideBar.vue";
import LogIn from "@/components/LogIn.vue";
import store from "./store";
import api from "@/lib/api";
import moment from "moment";
</script>

<template>
  <template v-if="loggedIn">
    <SideBar />
    <router-view :key="$route.path" />
  </template>
  <template v-else>
    <LogIn />
  </template>
</template>

<script>
export default {
  computed: {
    loggedIn() {
      if (
        localStorage.getItem("pstmSession") !== null &&
        localStorage.getItem("pstmUser") !== null &&
        localStorage.getItem("pstmToken") !== null
      ) {
        const minutes = moment().diff(
          moment(localStorage.getItem("pstmSession")),
          "minutes"
        );
        const storedToken = window.localStorage.getItem("pstmToken");
        const storedUser = JSON.parse(window.localStorage.getItem("pstmUser"));
        if (storedToken === null) {
          alert("Your session has expired, you'll be logged out!");
          store.commit("setUser", null);
          store.commit("setToken", null);
        } else if (minutes > 1) {
          api
            .get("engineers")
            .then((response) => {
              const user = response.data.find(
                (u) => u.username == storedUser.username
              );

              if (typeof user !== "undefined") {
                store.commit("setToken", storedToken);
                store.commit("setUser", user);
              } else {
                alert("Your session has expired, you'll be logged out!");
                store.commit("setUser", null);
                store.commit("setToken", null);
              }
            })
            .catch(() => {
              alert("Your session has expired, you'll be logged out!");
              store.commit("setUser", null);
              store.commit("setToken", null);
            });
        } else {
          store.commit("setToken", storedToken);
          store.commit("setUser", storedUser);
        }
      } else {
        store.commit("setUser", null);
        store.commit("setToken", null);
      }

      return store.state.user != null;
    },
  },
};
</script>

<style lang="scss">
#app {
  background: var(--backgroundColor);
  width: 100vw;
  height: 100vh;
  display: block;
}
</style>
