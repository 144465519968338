<template>
  <div
    id="manageResources"
    class="float-right"
    v-if="isAdmin || resourceType == 'hourentries'"
  >
    <div class="container mx-auto">
      <div class="title capitalize">
        {{ isHourEntry ? "Hour Entries" : resourceType }}
      </div>
      <div class="toolbar">
        <div class="filters">
          <template v-for="(filter, filterName) in filters">
            <div
              class="filter"
              :class="[
                filter.show && 'open',
                filter.value.length && 'active',
                filterName,
              ]"
              :key="filterName"
              v-if="
                filter.showIn.includes(resourceType) &&
                (isAdmin || !filter.adminOnly)
              "
            >
              <div class="select">
                <template v-if="filterName == 'date'">
                  <button
                    class="open"
                    :class="filter.show && 'active'"
                    @click="
                      filter.show = !filter.show;
                      initDaterangepicker('dateFilter');
                    "
                  >
                    <template
                      v-if="
                        filter.hasOwnProperty('label') && filter.label.length
                      "
                    >
                      {{ filter.label }}
                    </template>
                    <template v-else>Filter by {{ filterName }}</template>
                  </button>
                  <input
                    v-model="filter.value"
                    id="dateFilter"
                    class="datePicker-dateFilter"
                  />
                </template>
                <template v-else>
                  <button
                    class="open"
                    :class="filter.show && 'active'"
                    @click="filter.show = !filter.show"
                  >
                    Filter by {{ filterName }}
                  </button>

                  <template v-if="filter.show">
                    <ul>
                      <li
                        class="search"
                        v-if="
                          filter.hasOwnProperty('keyword') &&
                          this[filterName + 's'].length
                        "
                      >
                        <input
                          type="text"
                          :placeholder="'Search ' + filterName + '...'"
                          v-model="filter.keyword"
                          autocomplete="off"
                        />
                      </li>
                      <li
                        class="noResults smallText"
                        v-if="!this[filterName + 's'].length"
                      >
                        No {{ filterName }}s have been found
                      </li>
                      <template v-else>
                        <li
                          class="noResults smallText"
                          v-if="
                            filter.hasOwnProperty('keyword') &&
                            filter.keyword.length
                          "
                        >
                          No elements matched your search terms
                        </li>
                        <template
                          v-for="res in this[filterName + 's']"
                          :key="res.name"
                        >
                          <li
                            v-if="
                              res.name
                                .toLowerCase()
                                .includes(filter.keyword.toLowerCase())
                            "
                          >
                            <label
                              class="pointer"
                              :for="filterName + '-' + res.id"
                            >
                              <input
                                type="checkbox"
                                v-model="filter.value"
                                :value="res.id"
                                :id="filterName + '-' + res.id"
                              />
                              {{ res.name }}
                            </label>
                            <!-- <button
                              @click="
                                filter.value == res.id
                                  ? (filter.value = null)
                                  : (filter.value = res.id)
                              "
                              :class="filter.value != null && 'active'"
                            >
                              {{ res.name }}
                            </button> -->
                          </li>
                        </template>
                        <template v-if="filter.value.length">
                          <div class="clear">
                            <button
                              class="btn float-right"
                              @click="filter.value = []"
                            >
                              Clear
                            </button>
                          </div>
                        </template>
                      </template>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </div>

        <button
          class="btn primary float-right capitalize"
          @click="newResource(resourceType)"
        >
          Add
          {{ isHourEntry ? "Hour Entry" : resourceType }}
        </button>
      </div>
      <table class="smallText">
        <thead>
          <tr>
            <template v-for="(prop, propName) in resourceProps">
              <th
                v-if="propName != 'password'"
                :key="propName"
                class="sortable label"
                :class="prop.type"
              >
                {{ prop.title }}
                <button
                  @click="tableOrder.asc = !tableOrder.asc"
                  class="hasIcon onHover"
                  :class="[
                    tableOrder.prop == propName && 'show',
                    !tableOrder.asc && 'desc',
                  ]"
                >
                  Change Order
                </button>
              </th>
            </template>
            <th class="actions"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!this[resourceType].length">
            <td colspan="999" class="centerText">No records have been found</td>
          </tr>
          <tr v-for="(el, index) in this[resourceType]" :key="index">
            <template v-for="(prop, propName) in resourceProps">
              <td
                v-if="propName != 'password'"
                :key="propName + '-' + index"
                :class="prop.type"
              >
                <template
                  v-if="
                    Object.keys(resourceProperties).includes(propName + 's')
                  "
                >
                  {{ el[propName].name }}
                </template>
                <template v-else-if="prop.type == 'url'">
                  <a :href="el[propName]" target="_blank">
                    {{ el[propName] }}
                  </a>
                </template>
                <template v-else-if="prop.type == 'email'">
                  <a :href="'mailto:' + el[propName]" target="_blank">
                    {{ el[propName] }}
                  </a>
                </template>
                <template v-else-if="prop.type == 'boolean'">
                  <span class="icon" :class="el[propName] + ''">
                    {{ el[propName] }}
                  </span>
                </template>
                <template v-else-if="prop.type == 'timestamp'">
                  <span
                    :title="el[propName]"
                    v-html="formatTimestamp(el[propName])"
                  ></span>
                </template>
                <template v-else-if="prop.type == 'textarea'">
                  <span :title="el[propName]">
                    {{ el[propName] }}
                  </span>
                </template>
                <template v-else>
                  {{ el[propName] }}
                </template>
              </td>
            </template>
            <td class="actions">
              <button class="btn edit icon" @click="editResource(el)">
                Edit
              </button>
              <button
                v-if="isHourEntry"
                class="btn delete icon"
                @click="deleteResource(el.id)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="popUp" v-if="resource !== null">
        <div class="content">
          <span class="hasIcon close float-right" @click="resource = null">
          </span>
          <span class="title noMargin">
            {{ resource.hasOwnProperty("id") ? "Edit" : "Add" }}
            {{ isHourEntry ? "Hour Entry" : resourceType }}
          </span>
          <hr />

          <form @submit.prevent>
            <div class="flex flex-wrap">
              <template v-for="(prop, label) in resource" :key="label">
                <div
                  class="input"
                  :class="[
                    label == 'id' && 'hidden',
                    label != 'id' && resourceProps[label].hasOwnProperty('size')
                      ? 'basis-' + resourceProps[label].size
                      : 'basis-1/2',
                  ]"
                >
                  <label :for="label" v-if="label != 'id'">
                    {{
                      label == "enable" ? "Status" : resourceProps[label].title
                    }}
                    <template v-if="isRequired(label)">
                      <span class="required">*</span>
                    </template>
                  </label>
                  <template
                    v-if="Object.keys(resourceProperties).includes(label + 's')"
                  >
                    <!-- Resources coming from VueX Store -->
                    <select
                      :id="label"
                      v-model="resource[label]"
                      :required="isRequired(label)"
                      :disabled="
                        !isAdmin &&
                        resourceProps[label].hasOwnProperty('adminOnly')
                      "
                      :class="
                        !formIsValid &&
                        isRequired(label) &&
                        !resource[label].toString().length &&
                        'notValid'
                      "
                    >
                      <option value="" disabled>Select one...</option>
                      <template v-for="res in this[label + 's']" :key="res.id">
                        <option :value="res.id">
                          {{ res.name }}
                        </option>
                      </template>
                    </select>
                  </template>
                  <template v-else-if="label == 'role'">
                    <!-- Predefined -->
                    <select
                      :id="label"
                      :required="isRequired(label)"
                      v-model="resource[label]"
                      :class="
                        !formIsValid &&
                        isRequired(label) &&
                        !resource[label].length &&
                        'notValid'
                      "
                    >
                      <option value="" disabled>Select one...</option>
                      <option>engineer</option>
                      <option>admin</option>
                    </select>
                  </template>
                  <template v-else-if="label != 'id'">
                    <template v-if="resourceProps[label].type == 'boolean'">
                      <!-- Checkboxes / Switches -->
                      <label :for="label" class="switch">
                        Enable
                        <input
                          type="checkbox"
                          :id="label"
                          v-model="resource[label]"
                        />
                      </label>
                    </template>
                    <template v-else-if="resourceProps[label].type == 'time'">
                      <!-- Time (HH:MM) -->
                      <input
                        required
                        type="text"
                        class="time"
                        maxlength="2"
                        placeholder="HH"
                        autocomplete="off"
                        :id="label + '-hour'"
                        pattern="0[0-9]|1[0-9]|2[0-3]"
                        v-model="resource[label].hour"
                        @input="
                          resource[label].hour = resource[label].hour.replace(
                            /\D/g,
                            ''
                          )
                        "
                      />:
                      <input
                        required
                        type="text"
                        class="time"
                        maxlength="2"
                        placeholder="MM"
                        autocomplete="off"
                        :id="label + '-min'"
                        pattern="[0-5][0-9]"
                        v-model="resource[label].min"
                        @input="
                          resource[label].min = resource[label].min.replace(
                            /\D/g,
                            ''
                          )
                        "
                      />
                    </template>
                    <template
                      v-else-if="resourceProps[label].type == 'textarea'"
                    >
                      <!-- Full Text -->
                      <textarea
                        :required="isRequired(label)"
                        :id="label"
                        :key="label"
                        :data-field="label"
                        v-model="resource[label]"
                        :class="
                          !formIsValid &&
                          isRequired(label) &&
                          !resource[label].length &&
                          'notValid'
                        "
                      >
                      </textarea>
                    </template>
                    <template v-else>
                      <!-- Basic Text / Numeric -->
                      <input
                        :required="isRequired(label)"
                        :id="label"
                        :key="label"
                        autocomplete="off"
                        :data-field="label"
                        v-model="resource[label]"
                        :type="
                          resourceProps[label].type == 'url'
                            ? 'text'
                            : resourceProps[label].type
                        "
                        :step="resourceProps[label].type == 'number' && 'any'"
                        :min="resourceProps[label].type == 'number' && 0"
                        :max="
                          resourceProps[label].hasOwnProperty('max')
                            ? resourceProps[label].max
                            : ''
                        "
                        :class="[
                          resourceProps[label].type == 'timestamp' &&
                            'datePicker-' + label,
                          !formIsValid &&
                            isRequired(label) &&
                            !resource[label].toString().length &&
                            'notValid',
                        ]"
                        @focus="
                          resourceProps[label].type == 'timestamp' &&
                            initDatepicker(label)
                        "
                      />
                    </template>

                    <div v-if="hasDependencyError(label)" class="dialog alert">
                      <p class="smallText">
                        <template v-if="resourceProps[label].type == 'boolean'">
                          This status does not match the status on the chosen
                        </template>
                        <template
                          v-else-if="resourceProps[label].type == 'timestamp'"
                        >
                          This timestamp should not be set to a value earlier
                          than the one set in
                        </template>
                        <template
                          v-else-if="resourceProps[label].type == 'time'"
                        >
                          This time should not be set to a value earlier than
                          the one set in
                        </template>
                        <strong>
                          {{ resourceProps[label].dependsOn }}
                        </strong>
                      </p>
                    </div>
                  </template>
                  <div
                    v-if="
                      !resource.hasOwnProperty('id') &&
                      resourceProps[label].hasOwnProperty('unique') &&
                      typeof this[resourceType].find(
                        (res) => res[label] == resource[label]
                      ) !== 'undefined'
                    "
                    class="dialog alert floating"
                  >
                    <p class="smallText">
                      There is already another resource with the same
                      {{ label }}.
                    </p>
                  </div>
                </div>
              </template>
            </div>

            <button
              class="btn primary"
              @click="
                resource.hasOwnProperty('id')
                  ? updateResource(resource)
                  : createResource(resource)
              "
            >
              Save
            </button>
            <button
              type="button"
              class="btn secondary"
              @click="resource = null"
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/lib/api.js";
import moment from "moment";

// Load jQuery
const $ = require("jquery");
window.$ = $;

// Load datepicker
require("daterangepicker");
import("@/assets/css/daterangepicker.min.css");

export default {
  data() {
    return {
      polling: null,
      tableOrder: {
        asc: this.$route.params.resourceType != "hourentries",
        prop:
          this.$route.params.resourceType == "hourentries"
            ? "finishHour"
            : "name",
      },
      formIsValid: true,
      filters: {
        date: {
          adminOnly: false,
          showIn: ["hourentries"],
          show: false,
          active: false,
          label: "Last 3 Days",
          value:
            moment()
              .subtract(2, "d")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss") +
            " - " +
            moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
        },
        customer: {
          adminOnly: false,
          showIn: ["projects"],
          value: [],
          keyword: "",
          show: false,
          active: false,
        },
        engineer: {
          adminOnly: true,
          showIn: ["hourentries"],
          value: [],
          keyword: "",
          show: false,
          active: false,
        },
        project: {
          adminOnly: false,
          showIn: ["hourentries"],
          value: [],
          keyword: "",
          show: false,
          active: false,
        },
      },
      resourceProperties: {
        customers: {
          name: {
            title: "Name",
            type: "text",
            unique: true,
          },
          contactName: {
            required: false,
            title: "Contact Name",
            type: "text",
          },
          contactEmail: {
            required: false,
            title: "Contact Email",
            type: "email",
          },
          country: {
            title: "Country",
            type: "text",
          },
          glProject: {
            required: false,
            title: "GitLab Project",
            type: "url",
          },
          enable: {
            title: "Enabled",
            type: "boolean",
          },
          sinceCustomer: {
            required: false,
            title: "Since",
            type: "timestamp",
          },
        },
        engineers: {
          name: {
            title: "Name",
            type: "text",
          },
          username: {
            title: "Username",
            type: "text",
            unique: true,
          },
          password: {
            title: "Password",
            type: "password",
          },
          role: {
            title: "Role",
            type: "text",
          },
          email: {
            title: "Email",
            type: "email",
          },
          sinceWorking: {
            required: false,
            title: "Since",
            type: "timestamp",
          },
          timezone: {
            required: false,
            title: "Timezone",
            type: "text",
          },
          country: {
            title: "Country",
            type: "text",
          },
          enable: {
            title: "Enabled",
            type: "boolean",
          },
        },
        projects: {
          name: {
            title: "Name",
            type: "text",
            unique: true,
          },
          customer: {
            title: "Customer",
            type: "text",
          },
          type: {
            title: "Type",
            type: "text",
          },
          hours: {
            title: "Hours",
            type: "number",
            required: false,
          },
          startDate: {
            title: "Start",
            type: "timestamp",
            dependsOn: "customer.sinceCustomer",
            required: false,
          },
          endDate: {
            required: false,
            title: "End",
            type: "timestamp",
            dependsOn: "startDate",
          },
          enable: {
            title: "Enabled",
            type: "boolean",
            dependsOn: "customer.enable",
          },
        },
        hourentries: {
          engineer: {
            title: "Engineer",
            type: "text",
            adminOnly: true,
          },
          project: {
            title: "Project",
            type: "text",
          },
          startHour: {
            title: "Start",
            type: "timestamp",
            dependsOn: "project.startDate",
            setTimeValue: {
              from: {
                start: "startHour",
                finish: "finishHour",
              },
              to: ["totalHours", "billedHours"],
            },
          },
          finishHour: {
            required: false,
            title: "Finish",
            type: "timestamp",
            dependsOn: "startHour",
            setTimeValue: {
              from: {
                start: "startHour",
                finish: "finishHour",
              },
              to: ["totalHours", "billedHours"],
            },
          },
          totalHours: {
            title: "Total Hours",
            type: "number",
            max: 24,
          },
          billedHours: {
            title: "Billed Hours",
            type: "number",
            max: 24,
          },
          issueURL: {
            title: "Issue URL",
            type: "url",
            size: "full",
            required: false,
          },
          comment: {
            required: false,
            title: "Comment",
            type: "textarea",
            size: "full",
          },
        },
      },
      resource: null,
    };
  },

  mounted() {
    this.getResources(this.resourceType);
    this.polling = setInterval(() => {
      this.getResources(this.resourceType);
    }, 30000);

    $(document).on("click", (e) => {
      var $target = $(e.target);

      if ($(".filter.open").length && !$target.closest(".open").length) {
        Object.keys(this.filters).forEach((f) => {
          this.filters[f].show = false;
        });
      }
    });
  },

  beforeUnmount() {
    clearInterval(this.polling);
  },

  computed: {
    resourceType() {
      return this.$route.params.resourceType;
    },

    resourceProps() {
      return this.resourceProperties[this.$route.params.resourceType];
    },

    isHourEntry() {
      return this.$route.params.resourceType == "hourentries";
    },

    customers() {
      return this.sortTable(
        [...store.state.customers],
        this.tableOrder.prop,
        this.tableOrder.asc
      );
    },

    engineers() {
      return this.sortTable(
        [...store.state.engineers],
        this.tableOrder.prop,
        this.tableOrder.asc
      );
    },

    projects() {
      let projects = [...store.state.projects];

      if (this.resourceType == "projects") {
        Object.keys(this.filters).forEach((filter) => {
          if (
            this.filters[filter].showIn.includes(this.resourceType) &&
            this.filters[filter].value.length
          ) {
            if (filter != "date") {
              projects = projects.filter((e) =>
                this.filters[filter].value.includes(e[filter].id)
              );
            }
          }
        });
      }

      return this.sortTable(
        projects,
        this.tableOrder.prop,
        this.tableOrder.asc
      );
    },

    hourentries() {
      let hourentries = [...store.state.hourentries];

      Object.keys(this.filters).forEach((filter) => {
        if (
          this.filters[filter].showIn.includes(this.resourceType) &&
          this.filters[filter].value.length
        ) {
          if (filter != "date") {
            hourentries = hourentries.filter((e) =>
              this.filters[filter].value.includes(e[filter].id)
            );
          }
        }
      });

      return this.sortTable(hourentries, "finishHour", !this.tableOrder.asc);
    },

    isAdmin() {
      return store.state.user.role == "admin";
    },

    dateFilter() {
      let dateFilter = this.filters.date.value.split(" - ");

      dateFilter = (
        "dataStart/" +
        dateFilter[0] +
        "/dataEnd/" +
        dateFilter[1]
      ).replaceAll(":", "%3A");

      return this.isHourEntry
        ? this.isAdmin
          ? dateFilter
          : store.state.user.id + "/" + dateFilter
        : null;
    },
  },

  methods: {
    newResource(type) {
      const resources = {
        customers: {
          name: "",
          contactName: "",
          contactEmail: "",
          country: "",
          glProject: "",
          enable: true,
          sinceCustomer: "",
        },
        engineers: {
          name: "",
          username: "",
          password: "",
          role: "",
          email: "",
          sinceWorking: "",
          country: "",
          timezone: "",
          enable: true,
        },
        projects: {
          name: "",
          customer: "",
          type: "",
          hours: "",
          startDate: "",
          endDate: "",
          enable: true,
        },
        hourentries: {
          engineer: !this.isAdmin ? store.state.user.id : "",
          project: "",
          startHour: moment().format("YYYY-MM-DD HH:mm:ss"),
          finishHour: moment().add(1, "hours").format("YYYY-MM-DD HH:mm:ss"),
          totalHours: 1,
          billedHours: 1,
          issueURL: "",
          comment: "",
        },
      };
      this.resource = resources[type];
    },

    getResources(type) {
      if (this.dateFilter !== null) {
        api
          .getFiltered(type, this.dateFilter)
          .then((response) => {
            store.commit("addResources", {
              type: type,
              resources: response.data,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        api
          .get(type)
          .then((response) => {
            store.commit("addResources", {
              type: type,
              resources: response.data,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    parseResource(data) {
      let resource = Object.assign({}, data);

      if (Object.keys(resource).includes("id")) {
        delete resource.id;
      }

      Object.keys(resource).forEach((prop) => {
        if (resource[prop] === null) {
          return;
        }
        if (
          typeof resource[prop] === "object" &&
          Object.prototype.hasOwnProperty.call(resource[prop], "id")
        ) {
          resource[prop] = resource[prop].id;
        }

        if (this.resourceProps[prop].type == "timestamp") {
          resource[prop] = resource[prop].replace(" ", "T");
        }
      });

      return resource;
    },

    createResource(data) {
      let resource = this.parseResource(data);

      if (this.checkRequired(resource)) {
        api
          .post(this.resourceType, resource)
          .then(() => {
            this.getResources(this.resourceType);
            this.resource = null;
          })
          .catch((error) => {
            if (
              Object.prototype.hasOwnProperty.call(error.response, "data") &&
              Object.prototype.hasOwnProperty.call(
                error.response.data,
                "attributeName"
              )
            ) {
              alert(
                "Please check the value entered for " +
                  error.response.data.attributeName
              );
              let input = document.getElementById(
                error.response.data.attributeName
              );
              input.classList.add("notValid");
            } else {
              alert(this.resourceType + " could not be registered!");
            }

            console.log(error);
          });
      } else {
        alert("Please fill every mandatory field!");
      }
    },

    deleteResource(id) {
      api
        .delete(this.resourceType, id)
        .then(() => {
          this.getResources(this.resourceType);
        })
        .catch((error) => {
          console.log(error);
          alert(this.resourceType + " could not be deleted!");
        });
    },

    updateResource(data) {
      let resource = this.parseResource(data);

      if (this.checkRequired(resource)) {
        api
          .put(this.resourceType, data.id, resource)
          .then(() => {
            this.getResources(this.resourceType);
            this.resource = null;
          })
          .catch((error) => {
            console.log(error);
            alert("Resource could not be updated!");
          });
      }
    },

    editResource(el) {
      let resource = Object.assign({}, el);

      /* Object.keys(resource).forEach((prop) => {
        if (prop !== "id") {
          switch (this.resourceProps[prop].type) {
            case "timestamp":
              resource[prop] = resource[prop].slice(0, -9);
              break;
            case "time":
              resource[prop] = {
                hour: resource[prop].substr(11, 2),
                min: resource[prop].substr(14, 2),
              };
              break;
          }
        }
      }); */

      Object.keys(resource).forEach((prop) => {
        if (Object.keys(this.resourceProperties).includes(prop + "s")) {
          resource[prop] = resource[prop].id;
        }
      });

      this.resource = resource;
    },

    toggleResource(type, id) {
      let resource = Object.assign(
        {},
        this[type].find((el) => el.id == id)
      );

      resource.enable = !resource.enable;
      this.updateResource(resource);
    },

    // Single Datepicker
    initDatepicker(id) {
      const vc = this;
      const prop = this.resourceProps[id];
      const datePicker = $(".datePicker-" + id);
      let startDate = this.resource[id].length
        ? moment(this.resource[id])
        : moment();
      let minDate = null;
      let maxDate = null;

      if (
        Object.prototype.hasOwnProperty.call(prop, "dependsOn") &&
        !prop.dependsOn.includes(".") &&
        this.resource[prop.dependsOn].length
      ) {
        startDate = moment(this.resource[prop.dependsOn]);
        minDate = moment(startDate);
        if (this.isHourEntry) {
          maxDate = moment(startDate.endOf("day"));
        }
      }

      if (!datePicker.data("init")) {
        datePicker
          .daterangepicker({
            autoApply: true,
            autoUpdateInput: false,
            singleDatePicker: true,
            timePicker: true,
            showDropdowns: true,
            opens: "right",
            drops: "auto",
            startDate: startDate,
            ...(minDate !== null && { minDate: minDate }),
            ...(maxDate !== null && { maxDate: maxDate }),
            timePicker24Hour: true,
            timePickerSeconds: false,
            locale: {
              cancelLabel: "Cancel",
              format: "YYYY-MM-DDTHH:mm:ss",
            },
          })
          .on("apply.daterangepicker", function (ev, picker) {
            datePicker.val(picker.startDate.format("YYYY-MM-DD HH:mm") + ":00");
            vc.resource[id] =
              picker.startDate.format("YYYY-MM-DD HH:mm") + ":00";

            if (
              Object.prototype.hasOwnProperty.call(
                vc.resourceProps[id],
                "setTimeValue"
              )
            ) {
              vc.setTimeValue(id);
            }
          });

        datePicker.attr("data-init", true);
      }

      datePicker.focus();
    },

    // Range Datepicker
    initDaterangepicker(id) {
      const vc = this;
      const datePicker = $(".datePicker-" + id);

      if (!datePicker.data("init")) {
        datePicker
          .daterangepicker({
            autoApply: true,
            autoUpdateInput: false,
            singleDatePicker: false,
            timePicker: true,
            showDropdowns: true,
            opens: "right",
            drops: "auto",
            timePicker24Hour: true,
            timePickerSeconds: false,
            locale: {
              cancelLabel: "Cancel",
              format: "YYYY-MM-DDTHH:mm:ss",
            },
            alwaysShowCalendars: true,
            ranges: {
              Today: [moment().startOf("day"), moment().endOf("day")],
              Yesterday: [
                moment().subtract(1, "days").startOf("day"),
                moment().subtract(1, "days").endOf("day"),
              ],
              "Last 3 Days": [
                moment().subtract(2, "days").startOf("day"),
                moment().endOf("day"),
              ],
              "This Week": [
                moment().startOf("isoWeek"),
                moment().endOf("isoWeek"),
              ],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          })
          .on("apply.daterangepicker", (ev, picker) => {
            vc.filters.date.value =
              picker.startDate.format("YYYY-MM-DDTHH:mm:ss") +
              " - " +
              picker.endDate.format("YYYY-MM-DDTHH:mm:ss");

            vc.filters.date.label =
              picker.chosenLabel !== "Custom Range"
                ? picker.chosenLabel
                : picker.startDate.format("MMMM D, YYYY HH:mm") +
                  " - " +
                  picker.endDate.format("MMMM D, YYYY HH:mm");
            vc.getResources(vc.resourceType);
          })
          .on("shown.daterangepicker", () => {
            vc.filters.date.show = true;
          })
          .on("hide.daterangepicker", () => {
            vc.filters.date.show = false;
          });
        datePicker.attr("data-init", true);
      }

      if (!$(".daterangepicker.show-ranges.show-calendar").is(":visible")) {
        datePicker.focus();
      }
    },

    formatTimestamp(timestamp) {
      let t = "";

      if (timestamp != null) {
        t += "<span class='date'>" + timestamp.substr(0, 10) + "</span>";
        t += "<span class='time'>" + timestamp.substr(11, 19) + "</span>";

        if (timestamp.includes(".")) {
          var ms = "." + timestamp.split(".")[1];

          for (var i = ms.length; i <= 3; i++) {
            ms += "0";
          }
        } else {
          t += ".000";
        }
      }

      return t;
    },

    // Sort tables by an specific parameter
    sortTable(table, param, tableOrderAsc = true, type = "alphabetical") {
      table.sort((a, b) => {
        let modifier = 1;

        if (!tableOrderAsc) {
          modifier = -1;
        }

        a = eval("a." + param);
        b = eval("b." + param);

        switch (type) {
          case "timestamp":
            if (moment(a).isValid && moment(b).isValid) {
              if (moment(a).isBefore(moment(b))) {
                return -1 * modifier;
              }

              if (moment(a).isAfter(moment(b))) {
                return 1 * modifier;
              }
            } else if (!moment(a).isValid && moment(b).isValid) {
              return -1 * modifier;
            } else if (moment(a).isValid && !moment(b).isValid) {
              return 1 * modifier;
            }

            break;

          default: // alphabetical
            if (a < b) {
              return -1 * modifier;
            }

            if (a > b) {
              return 1 * modifier;
            }

            break;
        }

        return 0;
      });

      return table;
    },

    checkRequired(resource) {
      return (this.formIsValid =
        typeof Object.keys(resource).find(
          (prop) => this.isRequired(prop) && !resource[prop].toString().length
        ) === "undefined");
    },

    hasDependencyError(prop) {
      if (
        !Object.prototype.hasOwnProperty.call(
          this.resourceProps[prop],
          "dependsOn"
        )
      ) {
        return false;
      } else {
        let dependency = this.resourceProps[prop].dependsOn;

        if (dependency.includes(".")) {
          // Depends on another resource
          dependency = {
            type: dependency.split(".")[0],
            prop: dependency.split(".")[1],
          };

          let depRes = this[dependency.type + "s"].find(
            (r) => r.id == this.resource[dependency.type]
          );

          if (typeof depRes !== "undefined") {
            if (
              this.resourceProperties[dependency.type + "s"][dependency.prop]
                .type == "boolean"
            ) {
              return !depRes[dependency.prop] && this.resource[prop];
            } else if (
              this.resourceProperties[dependency.type + "s"][dependency.prop]
                .type == "timestamp"
            ) {
              return moment(depRes[dependency.prop]).isAfter(
                moment(this.resource[prop])
              );
            } else if (
              this.resourceProperties[dependency.type + "s"][dependency.prop]
                .type == "time" &&
              this.resource[prop].hour !== null &&
              depRes[dependency.prop].hour !== null
            ) {
              return (
                parseInt(depRes[dependency.prop].hour) >
                  parseInt(this.resource[prop].hour) ||
                (parseInt(depRes[dependency.prop].hour) ==
                  parseInt(this.resource[prop].hour) &&
                  this.resource[prop].min !== null &&
                  depRes[dependency.prop].min !== null &&
                  parseInt(depRes[dependency.prop].min) >
                    parseInt(this.resource[prop].min))
              );
            }
          } else {
            return false;
          }
        } else {
          if (this.resourceProps[dependency].type == "boolean") {
            // Boolean: dep.enable == res.enable
            return this.resource[dependency.prop] && this.resource[prop];
          } else if (this.resourceProps[dependency].type == "timestamp") {
            return moment(this.resource[dependency]).isAfter(
              moment(this.resource[prop])
            );
          } else if (
            this.resourceProps[dependency].type == "time" &&
            this.resource[prop].hour !== null &&
            this.resource[dependency].hour !== null
          ) {
            return (
              parseInt(this.resource[dependency].hour) >
                parseInt(this.resource[prop].hour) ||
              (parseInt(this.resource[dependency].hour) ==
                parseInt(this.resource[prop].hour) &&
                this.resource[prop].min !== null &&
                this.resource[dependency].min !== null &&
                parseInt(this.resource[dependency].min) >=
                  parseInt(this.resource[prop].min))
            );
          }
        }
      }
    },

    isRequired(prop) {
      return (
        !Object.prototype.hasOwnProperty.call(
          this.resourceProps[prop],
          "required"
        ) || this.resourceProps[prop].required
      );
    },

    setTimeValue(prop) {
      let startProp =
        this.resource[this.resourceProps[prop].setTimeValue.from.start];
      let finishProp =
        this.resource[this.resourceProps[prop].setTimeValue.from.finish];
      if (startProp.length && finishProp.length) {
        let start = moment(startProp);
        let finish = moment(finishProp);
        let duration = moment.duration(finish.diff(start)).asHours();

        this.resourceProps[prop].setTimeValue.to.forEach((dep) => {
          this.resource[dep] = Math.round(duration);
        });
      }
    },
  },
};
</script>

<style lang="scss">
#manageResources {
  width: calc(100vw - 250px);
  background: var(--backgroundColor);
  height: 100vh;
  overflow-y: auto;
  padding: 40px;
}

input#dateFilter {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}

.tableFooter {
  margin-top: 20px;
}

.clear {
  border-top: 1px solid var(--borderColor);
  display: inline-block;
  width: 100%;
  margin-bottom: -20px;
  margin-top: 10px;
}

.basis-1\/3 {
  flex-basis: 33.333%;
}

.filter.active button.open {
  border-color: var(--primary);
}

.daterangepicker .ranges li.active {
  background: var(--primary) !important;
}

.daterangepicker .ranges li[data-range-key="Custom Range"] {
  display: none;
}

.selectedDates {
  background: #fff;
  border: 1px solid var(--borderColor);
  padding: 5px 10px;
  border-radius: 5px;
}

.required {
  display: inline-block;
  color: var(--primary);
}

.filter.date {
  position: relative;

  button {
    padding-left: 30px;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIGlkPSJMYXllcl8xIiB4PSIwIiB5PSIwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNSAxNSIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMTUgMTUiPjxzdHlsZT4uc3Qwe2ZpbGw6bm9uZTtzdHJva2U6Izg1ODU5MDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMH08L3N0eWxlPjxwYXRoIGQ9Ik0zLjcuNVYzTTcuNS41VjNNMTEuMy41VjNNMTEuMyA5Ljl2MS4yYzAgLjEuMS4zLjMuM2gxLjJNMTQuNCA2LjdWMy40YzAtLjktLjctMS42LTEuNi0xLjZIMi4yYy0uOSAwLTEuNi43LTEuNiAxLjZ2OS40YzAgLjkuNyAxLjYgMS42IDEuNmg0LjUiIGNsYXNzPSJzdDAiLz48Y2lyY2xlIGN4PSIzLjciIGN5PSI2LjIiIHI9Ii4xIiBjbGFzcz0ic3QwIi8+PGNpcmNsZSBjeD0iNi4yIiBjeT0iNi4yIiByPSIuMSIgY2xhc3M9InN0MCIvPjxjaXJjbGUgY3g9IjguNyIgY3k9IjYuMiIgcj0iLjEiIGNsYXNzPSJzdDAiLz48Y2lyY2xlIGN4PSIxMS4zIiBjeT0iNi4yIiByPSIuMSIgY2xhc3M9InN0MCIvPjxjaXJjbGUgY3g9IjMuNyIgY3k9IjguNyIgcj0iLjEiIGNsYXNzPSJzdDAiLz48Y2lyY2xlIGN4PSI2LjIiIGN5PSI4LjciIHI9Ii4xIiBjbGFzcz0ic3QwIi8+PGNpcmNsZSBjeD0iMy43IiBjeT0iMTEuMyIgcj0iLjEiIGNsYXNzPSJzdDAiLz48Y2lyY2xlIGN4PSI2LjIiIGN5PSIxMS4zIiByPSIuMSIgY2xhc3M9InN0MCIvPjxjaXJjbGUgY3g9IjExLjMiIGN5PSIxMS4zIiByPSIzLjEiIGNsYXNzPSJzdDAiLz48L3N2Zz4=")
      10px center no-repeat #fff;
    background-size: 15px;
  }

  &:after {
    content: " ";
    display: block;
    border: 1px solid #888;
    width: 5px;
    height: 5px;
    border-top: 0;
    border-left: 0;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: rotate(45deg) translateY(-100%);
  }
}
</style>
